// TypeScriptが公式にnavigator.userAgentDataの型定義を追加したら以下定義の参照と関連パッケージ"user-agent-data-types"を削除する
/// <reference types="user-agent-data-types" />

const patterns = {
  native: {
    ios: /makuake-ios$/i,
    android: /makuake-android$/i,
  },
  browser: {
    ios: /iphone|ipod/i,
    android: /android/i,
    mobile:
      /iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone|IEMobile|dream|CUPCAKE|webOS|incognito|webmate/i,
  },
};

const ua = navigator.userAgent;
let platform: string;
let mobile: boolean;

const initUa = async function () {
  if (typeof navigator.userAgentData === 'undefined') {
    return;
  }
  const uaValues = await navigator.userAgentData.getHighEntropyValues([
    'platform',
  ]);
  platform = uaValues.platform ?? '';
  mobile = navigator.userAgentData.mobile;
};

const isNative = function () {
  return Object.values(patterns.native).some((pattern) => pattern.test(ua));
};

const isIOSNative = function () {
  return patterns.native.ios.test(ua);
};

const isIOS = function () {
  const checkPatterns = Object.values(patterns).map((pattern) => {
    const matcher = pattern.ios;
    if (matcher instanceof RegExp) {
      return matcher.test(ua);
    }
    return false;
  });
  const isIOSByUa = checkPatterns.some((checked) => checked);

  return isIOSByUa || platform === 'iOS';
};

const isAndroid = function () {
  const checkPatterns = Object.values(patterns).map((pattern) => {
    const matcher = pattern.android;
    if (matcher instanceof RegExp) {
      return matcher.test(ua);
    }
    return false;
  });
  const isAndroidByUa = checkPatterns.some((checked) => checked);
  return isAndroidByUa || platform === 'Android';
};

const isSP = function () {
  return isIOS() || isAndroid() || patterns.browser.mobile.test(ua) || !!mobile;
};

const isSPBrowser = function () {
  return isSP() && !isNative();
};

const isPC = function () {
  return !isSP();
};

export {
  initUa,
  isNative,
  isIOSNative,
  isIOS,
  isAndroid,
  isSP,
  isSPBrowser,
  isPC,
};
