/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * コンビニコード
 */
export enum CvsCode {
    LAWSON = 'LAWSON',
    FAMILYMART = 'FAMILYMART',
    MINISTOP = 'MINISTOP',
    SEICOMART = 'SEICOMART',
}
