/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * プロジェクトタイプ
 */
export enum ProjectType {
    CROWDFUNDING = 'CROWDFUNDING',
    DONATION = 'DONATION',
}
