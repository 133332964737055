/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * 決済手段
 */
export enum PaymentType {
    CARD = 'CARD',
    CVS = 'CVS',
    PAYEASY = 'PAYEASY',
    GANB = 'GANB',
}
