import { computed, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { initUa, isNative, isSP, isPC } from '@/utils/UserAgent';
type Platform = 'app' | 'sp' | 'pc';
interface DeviceInfo {
  app: ComputedRef<boolean>;
  loaded: Ref<boolean>;
  loadDevice: () => Promise<boolean>;
  name: Ref<'app' | 'sp' | 'pc'>;
  pc: ComputedRef<boolean>;
  sp: ComputedRef<boolean>;
}

const name = ref<Platform>('pc');
const loaded = ref<boolean>(false);
let initPromise: Promise<boolean>;

export default function useDevice(): DeviceInfo {
  const loadDevice = () => {
    if (initPromise) {
      return initPromise;
    }

    initPromise = new Promise<boolean>((resolve, reject) => {
      (async () => {
        try {
          await initUa();

          if (isNative()) name.value = 'app';
          else if (isSP()) name.value = 'sp';
          else if (isPC()) name.value = 'pc';

          loaded.value = true;
          resolve(true);
        } catch (error) {
          loaded.value = true;
          reject(error);
        }
      })();
    });

    return initPromise;
  };

  loadDevice();

  const pc = computed(() => name.value === 'pc');
  const sp = computed(() => name.value === 'sp');
  const app = computed(() => name.value === 'app');

  return {
    app,
    loaded,
    loadDevice,
    name,
    pc,
    sp,
  };
}
