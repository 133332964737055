<script setup lang="ts">
import BasicLayout from '@/components/common/BasicLayout.vue';
import { MAlert, MCard } from '@ca-crowdfunding/makuake-ui-n';

const props = defineProps<{
  errorMessages?: string[];
}>();
</script>

<template>
  <BasicLayout>
    <main>
      <div
        v-if="props.errorMessages && props.errorMessages.length > 0"
        class="wrap-error-message"
      >
        <MAlert class="error-message">
          <p v-for="e in props.errorMessages" :key="e">{{ e }}</p>
        </MAlert>
      </div>
      <div class="wrap">
        <MCard class="section">
          <div class="contents">
            <slot name="contents" />
          </div>
        </MCard>
      </div>
      <div class="wrap">
        <MCard class="buttons" color="transparent">
          <slot name="buttons" />
        </MCard>
      </div>
    </main>
  </BasicLayout>
</template>

<style scoped>
.wrap-error-message {
  display: flex;
  justify-content: center;
  width: 100%;
}

.error-message {
  width: 100%;
  max-width: 720px;
  margin: 0 20px 30px;
  white-space: pre-line;
}

.wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title {
  border-bottom: 1px solid #dddddd;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  line-height: 1.5;
  padding: 20px 22px 18px 22px;
  border-left: 5px solid #f7c600;
  margin: -1rem -1rem 0;
}

.contents {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 20pt;
}

.section {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  margin: 0 20px 50px;
  max-width: 720px;
  width: 100%;
}
.buttons {
  max-width: 720px;
  width: 100%;
  text-align: center;
}
</style>
