import './assets/css/main.css';
import '@ca-crowdfunding/makuake-ui-n/dist/makuake-ui-n.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import setupSentry from './sentry';
import { useErrorStore, errorStoreKey } from '@/store/error';
import { useActivityStore, activityStoreKey } from '@/store/activity';
import { useMulpay, mulpayStoreKey } from '@/store/mulpay';
import VueMultiPayment from '@mul-pay/mptoken-vue-js';
import { createGtm } from '@gtm-support/vue-gtm';

const app = createApp(App);
setupSentry(app);
app.provide(errorStoreKey, useErrorStore());
app.provide(mulpayStoreKey, useMulpay());
app.provide(activityStoreKey, useActivityStore());
app.use(router);
app.use(VueMultiPayment);

// GTM
const containerId: string = import.meta.env.VITE_GTM_CONTAINER_ID || '';
if (containerId !== '') {
  app.use(
    createGtm({
      id: containerId,
      defer: false, // ページの読み込みを高速化（トラッキング精度低下の可能性あり）
      debug: false, // コンソールログのデバッグを表示するかどうか
      loadScript: true, // GTMスクリプトを読み込むかどうか
      vueRouter: router, // vueRouterと自動的に同期するインスタンスを設定
      trackOnNextTick: true, // Vue.nextTick で trackView を呼び出すかどうか})
    }),
  );
}

app.mount('#app');
